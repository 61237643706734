import React, { useState, useEffect } from 'react';
import GradientBackground from './GradientBackground';
import './App.css';
import logo from './logo.png'; 
import { useGlitchEffect } from './glitchHover'; // Make sure the path is correct
import image2 from './logo.png'; 
import {
  ConnectionProvider,
  WalletProvider,
  useWallet,
} from '@solana/wallet-adapter-react';
import {
  WalletModalProvider,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

function App() {
  const [scrollY, setScrollY] = useState(0);
  const [glitchText, onMouseEnter, onMouseLeave] = useGlitchEffect('I am SAM');
  const [isInView] = useState(false);
  const network = clusterApiUrl('devnet'); // or 'mainnet-beta'
  const wallets = [new PhantomWalletAdapter()];
  
  // State to store the wallet address
  const [walletAddress, setWalletAddress] = useState('');

  // Use the wallet hook
  const wallet = useWallet();

  // Function to handle wallet connection
  const connectWallet = async () => {
    await wallet.connect();
    if (wallet.connected && wallet.publicKey) {
      setWalletAddress(wallet.publicKey.toString());
    }
  };

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const section2Start = window.innerHeight * 0.9; // Adjust 0.9 as needed based on actual layout

  // Adjust the logo size, position, and opacity based on scrollY
  const logoStyle = {
    bottom: scrollY > 100 ? '50%' : '0px',
    width: scrollY > 100 ? `${200 + scrollY / 5}px` : '200px',
    transform:
      scrollY > 100 ? `translate(-50%, -50%) scale(${1 + scrollY / 1000})` : 'translateX(-50%)',
    opacity: scrollY > section2Start ? '0' : '1', // Fade out as we reach Section 2
    transition: 'opacity 0.5s, transform 0.5s, bottom 0.5s, width 0.5s',
  };
  
function WalletInfo() {
  const wallet = useWallet();

  if (wallet.connected) {
    return <div>Connected to wallet: {wallet.publicKey.toString()}</div>;
  } else {
    return <div>Not connected</div>;
  }
}


  const downArrowSVG = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 19L5 12H9V5H15V12H19L12 19Z" fill="currentColor"/>
    </svg>
  );

  return (
    <div className="App">
      <GradientBackground />
      <div className="section">
        <img src={logo} alt="Logo" className="logo" style={logoStyle} />
        <h1 onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {glitchText}
        </h1>
        <div className="scroll-down">
          Scroll Down
          {downArrowSVG}
        </div>
      </div>
      <div className="section content-section">
        <div className="image-container">
          <img
            src={image2}
            alt="Descriptive Alt Text"
            className={`section-image ${isInView ? 'start-animation' : ''}`}
          />
        </div>
        <div className="text-container">
          <h3 className="new-text2">
            Sam says fair launch,
          </h3>
          <h3 className="new-text2">
            Sam says no tax
          </h3>
        </div>
      </div>
      <div className="text-container">
        <h3 className="new-text2">
          sam says we are super crypto
        </h3>
        <h3 className="new-text2">
          Sam says we are web3, connect Wallet.
        </h3> 
      </div>
      <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <div className="App">
            {/* ... other components */}

            {/* Wallet Connection Button */}
            <button onClick={connectWallet} className="custom-button">
              Connect Wallet
            </button>

            {/* Display Wallet Address */}
            {wallet.connected && (
              <div>
                Connected to wallet: {walletAddress}
              </div>
            )}

          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
      </div>
  
  );
}

export default App;




