
function GradientBackground() {
    const backgroundStyle = {
      backgroundSize: '100% 100%',
      backgroundPosition: '0% 50%',
      animation: 'gradientMovement 20s ease infinite',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: -1
    };

    return <div style={backgroundStyle}></div>;
}

export default GradientBackground;

  